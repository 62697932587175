import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableUser } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { SearchBar, Loader, ButtonModifier } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Pagination,
  Button,
  ButtonGroup,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useDownloadExcel } from "react-export-table-to-excel";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";

const DaftarUser = () => {
  const ref = useRef(null);
  const tableRef = useRef(null);
  const { user, setting, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();

  const [isFetchError, setIsFetchError] = useState(false);
  const [kodeCabang, setKodeCabang] = useState("");
  // Data Utama
  const [npwp15, setNpwp15] = useState("");
  const [nikNpwp16, setNikNpwp16] = useState("");
  const [nitku, setNitku] = useState("");
  const [nama, setNama] = useState("");
  const [bentukBadan, setBentukBadan] = useState("");

  // Data Lainnya
  const [alamat, setAlamat] = useState("");
  const [nomorTelepon, setNomorTelepon] = useState("");
  const [email, setEmail] = useState("");

  // Data KLU
  const [kodeKelompokKegiatanEkonomiKlu, setKodeKelompokKegiatanEkonomiKlu] =
    useState("");

  // Info Perpajakan
  const [kppAdministrasi, setKppAdministrasi] = useState("");
  const [noTeleponKpp, setNoTeleponKpp] = useState("");
  const [accountRepresentative, setAccountRepresentative] = useState("");
  const [statusWp, setStatusWp] = useState("");
  const [statusPkp, setStatusPkp] = useState("");

  // Identitas Penanggung Jawab
  const [namaPenanggungJawab, setNamaPenanggungJawab] = useState("");
  const [nikPenanggungJawab, setNikPenanggungJawab] = useState("");
  const [npwpPenanggungJawab, setNpwpPenanggungJawab] = useState("");
  const [jabatanPenanggungJawab, setJabatanPenanggungJawab] = useState("");
  const [kebangsaanPenanggungJawab, setKebangsaanPenanggungJawab] =
    useState("");
  const [alamatPjBadanPenanggungJawab, setAlamatPjBadanPenanggungJawab] =
    useState("");

  // Dokumen Pendirian
  const [nomorAkta, setNomorAkta] = useState("");
  const [tempatAkta, setTempatAkta] = useState("");
  const [namaNotaris, setNamaNotaris] = useState("");
  const [nomorAktaPerubahan, setNomorAktaPerubahan] = useState("");
  const [tipeUser, setTipeUser] = useState("");

  // Akses Master
  const [kategoriKlu, setKategoriKlu] = useState(false);
  const [golonganPokokKlu, setGolonganPokokKlu] = useState(false);
  const [golonganKlu, setGolonganKlu] = useState(false);
  const [subGolonganKlu, setSubGolonganKlu] = useState(false);
  const [kelompokKegiatanEkonomiKlu, setKelompokKegiatanEkonomiKlu] =
    useState(false);
  const [cabang, setCabang] = useState(false);

  // Akses Utility
  const [profilUser, setProfilUser] = useState(false);
  const [daftarUser, setDaftarUser] = useState(false);
  const [settingAkses, setSettingAkses] = useState(false);

  // Akses Kewajiban Perpajakan
  const [pphPasal25, setPphPasal25] = useState(false);
  const [pphPasal29, setPphPasal29] = useState(false);
  const [pphFinal, setPphFinal] = useState(false);
  const [pphPasal4Ayat2, setPphPasal4Ayat2] = useState(false);
  const [pphPasal15, setPphPasal15] = useState(false);
  const [pphPasal19, setPphPasal19] = useState(false);
  const [pphPasal21, setPphPasal21] = useState(false);
  const [pphPasal23, setPphPasal23] = useState(false);
  const [pphPasal26, setPphPasal26] = useState(false);

  const [previewPdf, setPreviewPdf] = useState(false);
  const [previewExcel, setPreviewExcel] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUser] = useState([]);
  const [usersPagination, setUsersPagination] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  useEffect(() => {
    {
      user.tipeUser === "OWNER"
        ? getUsersAllCabangPagination()
        : getUserPerCabangData();
    }
    id && getUserById();
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [id, page, searchTerm]);

  const getUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${tempUrl}/usersPerCabang`, {
        tipeAdmin: user.tipeUser,
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      });
      setUser(response.data);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getUsersAllCabang = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${tempUrl}/users`, {
        tipeAdmin: user.tipeUser,
        _id: user.id,
        token: user.token,
      });
      setUser(response.data);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getUsersAllCabangPagination = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${tempUrl}/usersPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        }
      );
      setUsersPagination(response.data.users);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getUserPerCabangData = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${tempUrl}/usersPerCabangPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        }
      );
      setUsersPagination(response.data.users);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getUserById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/findUser/${id}`, {
        _id: user.id,
        token: user.token,
      });
      // Data Utama
      setNpwp15(response.data.npwp15);
      setNikNpwp16(response.data.nikNpwp16);
      setNitku(response.data.nitku);
      setNama(response.data.nama);
      setBentukBadan(response.data.bentukBadan);

      // Data Lainnya
      setAlamat(response.data.alamat);
      setNomorTelepon(response.data.nomorTelepon);
      setEmail(response.data.email);

      // Data KLU
      setKodeKelompokKegiatanEkonomiKlu(
        `${response.data.kelompokkegiatanekonomiklu.kodeKelompokKegiatanEkonomiKlu} - ${response.data.kelompokkegiatanekonomiklu.namaKelompokKegiatanEkonomiKlu}`
      );

      // Info Perpajakan
      setKppAdministrasi(response.data.kppAdministrasi);
      setNoTeleponKpp(response.data.noTeleponKpp);
      setAccountRepresentative(response.data.accountRepresentative);
      setStatusWp(response.data.statusWp);
      setStatusPkp(response.data.statusPkp);

      // Identitas Penanggung Jawab
      setNamaPenanggungJawab(response.data.namaPenanggungJawab);
      setNikPenanggungJawab(response.data.nikPenanggungJawab);
      setNpwpPenanggungJawab(response.data.npwpPenanggungJawab);
      setJabatanPenanggungJawab(response.data.jabatanPenanggungJawab);
      setKebangsaanPenanggungJawab(response.data.kebangsaanPenanggungJawab);
      setAlamatPjBadanPenanggungJawab(
        response.data.alamatPjBadanPenanggungJawab
      );

      // Dokumen Pendirian
      setNomorAkta(response.data.nomorAkta);
      setTempatAkta(response.data.tempatAkta);
      setNamaNotaris(response.data.namaNotaris);
      setNomorAktaPerubahan(response.data.nomorAktaPerubahan);
      setTipeUser(response.data.tipeUser);
      setKodeCabang(response.data.cabang);

      // Akses Master
      setKategoriKlu(response.data.akses.kategoriKlu);
      setGolonganPokokKlu(response.data.akses.golonganPokokKlu);
      setGolonganKlu(response.data.akses.golonganKlu);
      setSubGolonganKlu(response.data.akses.subGolonganKlu);
      setKelompokKegiatanEkonomiKlu(
        response.data.akses.kelompokKegiatanEkonomiKlu
      );
      setCabang(response.data.akses.cabang);

      // Akses Utility
      setProfilUser(response.data.akses.profilUser);
      setDaftarUser(response.data.akses.daftarUser);
      setSettingAkses(response.data.akses.setting);

      // Akses Kewajiban Perpajakan
      setPphPasal25(response.data.kewajibanPerpajakan.pphPasal25);
      setPphPasal29(response.data.kewajibanPerpajakan.pphPasal29);
      setPphFinal(response.data.kewajibanPerpajakan.pphFinal);
      setPphPasal4Ayat2(response.data.kewajibanPerpajakan.pphPasal4Ayat2);
      setPphPasal15(response.data.kewajibanPerpajakan.pphPasal15);
      setPphPasal19(response.data.kewajibanPerpajakan.pphPasal19);
      setPphPasal21(response.data.kewajibanPerpajakan.pphPasal21);
      setPphPasal23(response.data.kewajibanPerpajakan.pphPasal23);
      setPphPasal26(response.data.kewajibanPerpajakan.pphPasal26);
    }
  };

  const deleteUser = async (id) => {
    setLoading(true);
    try {
      await axios.post(`${tempUrl}/users/deleteUser/${id}`, {
        tipeAdmin: user.tipeUser,
        _id: user.id,
        token: user.token,
      });
      setSearchTerm("");
      // Data Utama
      setNpwp15("");
      setNikNpwp16("");
      setNitku("");
      setNama("");
      setBentukBadan("");

      // Data Lainnya
      setAlamat("");
      setNomorTelepon("");
      setEmail("");

      // Data KLU
      setKodeKelompokKegiatanEkonomiKlu("");

      // Info Perpajakan
      setKppAdministrasi("");
      setNoTeleponKpp("");
      setAccountRepresentative("");
      setStatusWp("");
      setStatusPkp("");

      // Identitas Penanggung Jawab
      setNamaPenanggungJawab("");
      setNikPenanggungJawab("");
      setNpwpPenanggungJawab("");
      setJabatanPenanggungJawab("");
      setKebangsaanPenanggungJawab("");
      setAlamatPjBadanPenanggungJawab("");

      // Dokumen Pendirian
      setNomorAkta("");
      setTempatAkta("");
      setNamaNotaris("");
      setNomorAktaPerubahan("");
      setTipeUser("");
      setKodeCabang("");

      if (user.id == id) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      } else {
        navigate("/daftarUser");
      }
    } catch (error) {
      if (error.response.data.message.includes("foreign key")) {
        alert(`${nama} tidak bisa dihapus karena sudah ada data!`);
      }
    }
    setLoading(false);
  };

  const downloadPdf = () => {
    var date = new Date();
    var current_date =
      date.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      (date.getMonth() + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      date.getFullYear();
    var current_time =
      date.getHours().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getMinutes().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getSeconds().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    const doc = new jsPDF();
    doc.setFontSize(10);
    doc.text(
      `${setting.namaPerusahaan} - ${setting.kabupatenPerusahaan}`,
      15,
      10
    );
    doc.text(`${setting.alamatPerusahaan}`, 15, 15);
    doc.setFontSize(14);
    doc.text(`Daftar User`, 85, 30);
    doc.setFontSize(8);
    doc.text(
      `Dicetak Oleh: ${user.nama} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.autoTable({
      html: "#table",
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      styles: {
        fontSize: 8,
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Daftar User",
    sheet: "DaftarUser",
  });

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const textRightSmall = {
    textAlign: screenSize >= 650 && "right",
    fontSize: "14px",
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Container ref={ref}>
      <h3>Utility</h3>
      <h5 style={{ fontWeight: 400 }}>Daftar User</h5>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            color="primary"
            startIcon={<SearchIcon />}
            onClick={() => {
              if (user.tipeUser === "OWNER") {
                getUsersAllCabang();
              } else {
                getUsers();
              }
              setPreviewPdf(!previewPdf);
              setPreviewExcel(false);
            }}
          >
            PDF
          </Button>
          <Button
            color="secondary"
            startIcon={<SearchIcon />}
            onClick={() => {
              if (user.tipeUser === "OWNER") {
                getUsersAllCabang();
              } else {
                getUsers();
              }
              setPreviewExcel(!previewExcel);
              setPreviewPdf(false);
            }}
          >
            Excel
          </Button>
        </ButtonGroup>
      </Box>
      {previewPdf && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={() => downloadPdf()}
          >
            CETAK
          </Button>
          <table class="table" id="table">
            <thead>
              <tr>
                <th>Nama</th>
                <th>Tipe User</th>
                <th>Cabang</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={user.id}>
                  <td>{user.nama}</td>
                  <td>{user.tipeUser}</td>
                  <td>
                    {user.cabang.id} - {user.cabang.namaCabang}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div>
        {previewExcel && (
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={onDownload}
          >
            EXCEL
          </Button>
        )}
        <table ref={tableRef}>
          {previewExcel && (
            <tbody>
              <tr>
                <th>Nama</th>
                <th>Tipe User</th>
                <th>Cabang</th>
              </tr>
              {users.map((user, index) => (
                <tr key={user.id}>
                  <td>{user.nama}</td>
                  <td>{user.tipeUser}</td>
                  <td>
                    {user.cabang.id} - {user.cabang.namaCabang}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      <Box sx={buttonModifierContainer}>
        <ButtonModifier
          id={id}
          kode={id}
          addLink={`/daftarUser/tambahUser`}
          editLink={`/daftarUser/${id}/edit`}
          deleteUser={deleteUser}
          nameUser={nama}
        />
      </Box>
      {id && (
        <Container>
          <hr />
          <Form>
            <Card>
              <Card.Header>Data Utama</Card.Header>
              <Card.Body>
                <Box sx={textFieldContainer}>
                  <Box sx={textFieldWrapper}>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            NPWP15 :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control value={npwp15} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            NIK/NPWP16 :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control value={nikNpwp16} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            NITKU :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control value={nitku} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Box>
                  <Box sx={[textFieldWrapper, secondWrapper]}>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Nama :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control value={nama} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Tipe User :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control value={tipeUser} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Cabang :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={`${kodeCabang.id} - ${kodeCabang.namaCabang}`}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Box>
                </Box>
              </Card.Body>
            </Card>
            <Card style={{ marginTop: "20px" }}>
              <Card.Header>Data Lainnya</Card.Header>
              <Card.Body>
                <Box sx={textFieldContainer}>
                  <Box sx={textFieldWrapper}>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Alamat :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control value={alamat} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Nomor Telepon :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={nomorTelepon}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Email :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control value={email} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Box>
                  <Box sx={[textFieldWrapper, secondWrapper]}></Box>
                </Box>
              </Card.Body>
            </Card>
            <Card style={{ marginTop: "20px" }}>
              <Card.Header>Data KLU</Card.Header>
              <Card.Body>
                <Box sx={textFieldContainer}>
                  <Box sx={textFieldWrapper}>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            KEL KLU :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              as="textarea"
                              rows={4}
                              value={kodeKelompokKegiatanEkonomiKlu}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Box>
                  <Box sx={[textFieldWrapper, secondWrapper]}></Box>
                </Box>
              </Card.Body>
            </Card>
            <Card style={{ marginTop: "20px" }}>
              <Card.Header>Info Perpajakan</Card.Header>
              <Card.Body>
                <Box sx={textFieldContainer}>
                  <Box sx={textFieldWrapper}>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            KPP Administrasi :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kppAdministrasi}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            No. Telepon KPP :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={noTeleponKpp}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Account Representative :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={accountRepresentative}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Box>
                  <Box sx={[textFieldWrapper, secondWrapper]}>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Status Wp :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control value={statusWp} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Status Pkp :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control value={statusPkp} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Box>
                </Box>
              </Card.Body>
            </Card>
            <Card style={{ marginTop: "20px" }}>
              <Card.Header>Identitas Penanggung Jawab</Card.Header>
              <Card.Body>
                <Box sx={textFieldContainer}>
                  <Box sx={textFieldWrapper}>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Nama Penanggung Jawab :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={namaPenanggungJawab}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Nik Penanggung Jawab :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={nikPenanggungJawab}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Npwp Penanggung Jawab :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={npwpPenanggungJawab}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Box>
                  <Box sx={[textFieldWrapper, secondWrapper]}>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Jabatan Penanggung Jawab :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={jabatanPenanggungJawab}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kebangsaan Penanggung Jawab :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kebangsaanPenanggungJawab}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Alamat Pj Badan Penanggung Jawab :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={alamatPjBadanPenanggungJawab}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Box>
                </Box>
              </Card.Body>
            </Card>
            <Card style={{ marginTop: "20px" }}>
              <Card.Header>Dokumen Pendirian</Card.Header>
              <Card.Body>
                <Box sx={textFieldContainer}>
                  <Box sx={textFieldWrapper}>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Nomor Akta :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control value={nomorAkta} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Tempat Akta :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={tempatAkta}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Box>
                  <Box sx={[textFieldWrapper, secondWrapper]}>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Nama Notaris :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={namaNotaris}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Nomor Akta Perubahan :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={nomorAktaPerubahan}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Box>
                </Box>
              </Card.Body>
            </Card>
          </Form>
          {user.tipeUser !== "ADMIN" && (
            <>
              <Card style={{ marginTop: "20px" }}>
                <Card.Header>Hak Akses User</Card.Header>
                <Card.Body>
                  <Box sx={showDataContainer}>
                    <Box sx={showDataWrapper}>
                      <Typography variant="p">Master</Typography>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={kategoriKlu} disabled />}
                          label="Kategori Klu"
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox checked={golonganPokokKlu} disabled />
                          }
                          label="Golongan Pokok Klu"
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={golonganKlu} disabled />}
                          label="Golongan Klu"
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox checked={subGolonganKlu} disabled />
                          }
                          label="Sub Golongan Klu"
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={kelompokKegiatanEkonomiKlu}
                              disabled
                            />
                          }
                          label="Kelompok Kegiatan Ekonomi Klu"
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={cabang} disabled />}
                          label="Cabang"
                        />
                      </FormGroup>
                    </Box>
                    <Box sx={[showDataWrapper, secondWrapper]}>
                      <Typography variant="p">Utility</Typography>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={profilUser} disabled />}
                          label="Profil User"
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={daftarUser} disabled />}
                          label="Daftar User"
                        />
                      </FormGroup>
                      {user.tipeUser === "OWNER" && (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox checked={settingAkses} disabled />
                            }
                            label="Setting"
                          />
                        </FormGroup>
                      )}
                    </Box>
                  </Box>
                </Card.Body>
              </Card>
              <Card style={{ marginTop: "20px" }}>
                <Card.Header>Akses Kewajiban Perpajakan</Card.Header>
                <Card.Body>
                  <Box sx={showDataContainer}>
                    <Box sx={showDataWrapper}>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={pphPasal25} disabled />}
                          label="PPh Pasal 25"
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={pphPasal29} disabled />}
                          label="PPh Pasal 29"
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={pphFinal} disabled />}
                          label="PPh Final"
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox checked={pphPasal4Ayat2} disabled />
                          }
                          label="PPh Pasal 4 ayat 2"
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={pphPasal15} disabled />}
                          label="PPh Pasal 15"
                        />
                      </FormGroup>
                    </Box>
                    <Box sx={[showDataWrapper, secondWrapper]}>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={pphPasal19} disabled />}
                          label="PPh Pasal 19"
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={pphPasal21} disabled />}
                          label="PPh Pasal 21"
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={pphPasal23} disabled />}
                          label="PPh Pasal 23"
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={pphPasal26} disabled />}
                          label="PPh Pasal 26"
                        />
                      </FormGroup>
                    </Box>
                  </Box>
                </Card.Body>
              </Card>
            </>
          )}
        </Container>
      )}
      <hr />
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableUser currentPosts={usersPagination} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Container>
  );
};

export default DaftarUser;

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const showDataContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const checkboxTitle = {
  marginBottom: 0,
};

const secondCheckboxTitle = {
  marginTop: 15,
  marginBottom: 0,
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};
