import React, { useContext, useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./styles.scss";
import { Sidebar, Footer } from "./components";
import { AuthContext } from "./contexts/AuthContext";
import { useStateContext } from "./contexts/ContextProvider";
import {
  Login,
  ProfilUser,
  UbahProfilUser,
  DaftarUser,
  TambahUser,
  UbahUser,
  TampilSetting,
  UbahSetting,
  TampilMarketing,
  TambahMarketing,
  UbahMarketing,
  TampilKategoriKlu,
  TambahKategoriKlu,
  UbahKategoriKlu,
  TampilGolonganPokokKlu,
  TambahGolonganPokokKlu,
  UbahGolonganPokokKlu,
  TampilGolonganKlu,
  UbahGolonganKlu,
  TambahGolonganKlu,
  TampilSubGolonganKlu,
  UbahSubGolonganKlu,
  TambahSubGolonganKlu,
  TampilKelompokKegiatanEkonomiKlu,
  UbahKelompokKegiatanEkonomiKlu,
  TambahKelompokKegiatanEkonomiKlu,
  TampilCabang,
  TambahCabang,
  UbahCabang,
} from "./pages/index";
import { FaBars } from "react-icons/fa";

const App = () => {
  const { screenSize, setScreenSize } = useStateContext();
  // const { collapseSidebar } = useProSidebar();
  const { user } = useContext(AuthContext);
  // const [open, setOpen] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  const USERRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user) {
      return children;
    }

    return <Navigate to="/login" />;
  };

  const MARKETINGRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.marketing) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const KATEGORIKLURoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.kategoriKlu) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const GOLONGANPOKOKKLURoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.golonganPokokKlu) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const GOLONGANKLURoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.golonganKlu) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const SUBGOLONGANKLURoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.subGolonganKlu) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const KELOMPOKKEGIATANEKONOMIKLURoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.kelompokKegiatanEkonomiKlu) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const CABANGRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.cabang) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const PROFILUSERRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.profilUser) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const SETTINGRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.setting) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const DAFTARUSERRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.daftarUser) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={`app ${toggled ? "toggled" : ""}`}>
      {user && (
        <Sidebar
          collapsed={collapsed}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
        />
      )}

      <main>
        <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
          <FaBars />
        </div>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          {/* Profil User */}
          <Route
            path="/profilUser"
            element={
              <PROFILUSERRoute>
                <ProfilUser />
              </PROFILUSERRoute>
            }
          />
          <Route
            path="/profilUser/:id/edit"
            element={
              <PROFILUSERRoute>
                <UbahProfilUser />
              </PROFILUSERRoute>
            }
          />
          {/* Daftar User */}
          <Route
            path="/daftarUser"
            element={
              <DAFTARUSERRoute>
                <DaftarUser />
              </DAFTARUSERRoute>
            }
          />
          <Route
            path="/daftarUser/:id"
            element={
              <DAFTARUSERRoute>
                <DaftarUser />
              </DAFTARUSERRoute>
            }
          />
          <Route
            path="/daftarUser/:id/edit"
            element={
              <DAFTARUSERRoute>
                <UbahUser />
              </DAFTARUSERRoute>
            }
          />
          <Route
            path="/daftarUser/tambahUser"
            element={
              <DAFTARUSERRoute>
                <TambahUser />
              </DAFTARUSERRoute>
            }
          />
          {/* Setting */}
          <Route
            path="/setting"
            element={
              <SETTINGRoute>
                <TampilSetting />
              </SETTINGRoute>
            }
          />
          <Route
            path="/setting/:id/edit"
            element={
              <SETTINGRoute>
                <UbahSetting />
              </SETTINGRoute>
            }
          />
          {/* Marketing */}
          <Route
            path="/marketing"
            element={
              <MARKETINGRoute>
                <TampilMarketing />
              </MARKETINGRoute>
            }
          />
          <Route
            path="/marketing/:id"
            element={
              <MARKETINGRoute>
                <TampilMarketing />
              </MARKETINGRoute>
            }
          />
          <Route
            path="/marketing/tambahMarketing"
            element={
              <MARKETINGRoute>
                <TambahMarketing />
              </MARKETINGRoute>
            }
          />
          <Route
            path="/marketing/:id/edit"
            element={
              <MARKETINGRoute>
                <UbahMarketing />
              </MARKETINGRoute>
            }
          />
          {/* Kategori Klu */}
          <Route
            path="/kategoriKlu"
            element={
              <KATEGORIKLURoute>
                <TampilKategoriKlu />
              </KATEGORIKLURoute>
            }
          />
          <Route
            path="/kategoriKlu/:id"
            element={
              <KATEGORIKLURoute>
                <TampilKategoriKlu />
              </KATEGORIKLURoute>
            }
          />
          <Route
            path="/kategoriKlu/:id/edit"
            element={
              <KATEGORIKLURoute>
                <UbahKategoriKlu />
              </KATEGORIKLURoute>
            }
          />
          <Route
            path="/kategoriKlu/tambahKategoriKlu"
            element={
              <KATEGORIKLURoute>
                <TambahKategoriKlu />
              </KATEGORIKLURoute>
            }
          />
          {/* Golongan Pokok Klu */}
          <Route
            path="/golonganPokokKlu"
            element={
              <GOLONGANPOKOKKLURoute>
                <TampilGolonganPokokKlu />
              </GOLONGANPOKOKKLURoute>
            }
          />
          <Route
            path="/golonganPokokKlu/:id"
            element={
              <GOLONGANPOKOKKLURoute>
                <TampilGolonganPokokKlu />
              </GOLONGANPOKOKKLURoute>
            }
          />
          <Route
            path="/golonganPokokKlu/:id/edit"
            element={
              <GOLONGANPOKOKKLURoute>
                <UbahGolonganPokokKlu />
              </GOLONGANPOKOKKLURoute>
            }
          />
          <Route
            path="/golonganPokokKlu/tambahGolonganPokokKlu"
            element={
              <GOLONGANPOKOKKLURoute>
                <TambahGolonganPokokKlu />
              </GOLONGANPOKOKKLURoute>
            }
          />
          {/* Golongan Klu */}
          <Route
            path="/golonganKlu"
            element={
              <GOLONGANKLURoute>
                <TampilGolonganKlu />
              </GOLONGANKLURoute>
            }
          />
          <Route
            path="/golonganKlu/:id"
            element={
              <GOLONGANKLURoute>
                <TampilGolonganKlu />
              </GOLONGANKLURoute>
            }
          />
          <Route
            path="/golonganKlu/:id/edit"
            element={
              <GOLONGANKLURoute>
                <UbahGolonganKlu />
              </GOLONGANKLURoute>
            }
          />
          <Route
            path="/golonganKlu/tambahGolonganKlu"
            element={
              <GOLONGANKLURoute>
                <TambahGolonganKlu />
              </GOLONGANKLURoute>
            }
          />
          {/* Sub Golongan Klu */}
          <Route
            path="/subGolonganKlu"
            element={
              <SUBGOLONGANKLURoute>
                <TampilSubGolonganKlu />
              </SUBGOLONGANKLURoute>
            }
          />
          <Route
            path="/subGolonganKlu/:id"
            element={
              <SUBGOLONGANKLURoute>
                <TampilSubGolonganKlu />
              </SUBGOLONGANKLURoute>
            }
          />
          <Route
            path="/subGolonganKlu/:id/edit"
            element={
              <SUBGOLONGANKLURoute>
                <UbahSubGolonganKlu />
              </SUBGOLONGANKLURoute>
            }
          />
          <Route
            path="/subGolonganKlu/tambahSubGolonganKlu"
            element={
              <SUBGOLONGANKLURoute>
                <TambahSubGolonganKlu />
              </SUBGOLONGANKLURoute>
            }
          />
          {/* Kelompok Kegiatan Ekonomi Klu */}
          <Route
            path="/kelompokKegiatanEkonomiKlu"
            element={
              <KELOMPOKKEGIATANEKONOMIKLURoute>
                <TampilKelompokKegiatanEkonomiKlu />
              </KELOMPOKKEGIATANEKONOMIKLURoute>
            }
          />
          <Route
            path="/kelompokKegiatanEkonomiKlu/:id"
            element={
              <KELOMPOKKEGIATANEKONOMIKLURoute>
                <TampilKelompokKegiatanEkonomiKlu />
              </KELOMPOKKEGIATANEKONOMIKLURoute>
            }
          />
          <Route
            path="/kelompokKegiatanEkonomiKlu/:id/edit"
            element={
              <KELOMPOKKEGIATANEKONOMIKLURoute>
                <UbahKelompokKegiatanEkonomiKlu />
              </KELOMPOKKEGIATANEKONOMIKLURoute>
            }
          />
          <Route
            path="/kelompokKegiatanEkonomiKlu/tambahKelompokKegiatanEkonomiKlu"
            element={
              <KELOMPOKKEGIATANEKONOMIKLURoute>
                <TambahKelompokKegiatanEkonomiKlu />
              </KELOMPOKKEGIATANEKONOMIKLURoute>
            }
          />
          {/*  Cabang */}
          <Route
            path="/cabang"
            element={
              <CABANGRoute>
                <TampilCabang />
              </CABANGRoute>
            }
          />
          <Route
            path="/cabang/:id"
            element={
              <CABANGRoute>
                <TampilCabang />
              </CABANGRoute>
            }
          />
          <Route
            path="/cabang/:id/edit"
            element={
              <CABANGRoute>
                <UbahCabang />
              </CABANGRoute>
            }
          />
          <Route
            path="/cabang/tambahCabang"
            element={
              <CABANGRoute>
                <TambahCabang />
              </CABANGRoute>
            }
          />
        </Routes>
        <Footer />
      </main>
    </div>
  );
};

export default App;
